<template>
  <highcharts
    :constructorType="'mapChart'"
    class="hc"
    :options="chartOptions"
    ref="chart"
  />
</template>

<script setup lang="ts">
import worldMap from "@/assets/kz-all.topo";
import { useStore } from "@/store";
import { computed, ref } from "vue";

const store = useStore();
const chart = ref();

const data = computed(() => {
  return store.raions.find(
    (e) => +e.id === +store?.currentProject["kato-raion"]
  );
});

const dataParse = data?.value ? data?.value?.polygon : [[[[0, 0]]]];

console.log(dataParse);

const chartOptions = {
  chart: {
    map: worldMap,
    backgroundColor: "transparent",
  },
  title: {
    text: "",
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      alignTo: "spacingBox",
    },
  },
  colorAxis: {
    min: -30,
    stops: [
      [0, "#a8252b"], // Green
      [0.2, "#f6530f"], // Yellow
      [0.5, "#83966e"], // Yellow
      [0.7, "#1bc7e0"], // Yellow
      [1, "#38ae71"], // Red
    ],
    max: 30,
  },
  mapView: data.value
    ? {
        center: [dataParse[0][0][0][0], dataParse[0][0][0][1]],
        zoom: 7,
      }
    : {},
  plotOptions: {
    mappoint: {
      keys: ["lat", "lon", "name", "y"],
      marker: {
        symbol: "mapmarker",
        radius: 10,
      },
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      name: "Random data",
      dataLabels: {
        enabled: true,
      },
      borderColor: "#84c2d7",
      borderWidth: 0.5,
      allAreas: true,
    },

    data.value
      ? {
          // Specify points using lat/lon
          type: "mappoint",
          name: "Cities",
          accessibility: {
            point: {
              valueDescriptionFormat:
                "{xDescription}. Lat: " +
                "{point.lat:.2f}, lon: {point.lon:.2f}.",
            },
          },
          data: [[dataParse[0][0][0][1], dataParse[0][0][0][0], "", 23]],
        }
      : {},
  ],
};
</script>

<style scoped lang="scss"></style>

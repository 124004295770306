<script setup lang="ts">
import { HiBriefcase } from "@kalimahapps/vue-icons";
import { useStore } from "@/store";

import { ref } from "vue";
import { Collapse } from "vue-collapsed";
// eslint-disable-next-line no-undef
defineProps<{
  data: any;
}>();

const store = useStore();

const isExpanded = ref(false);
</script>

<template>
  <div>
    <div
      @click="
        () => {
          isExpanded = !isExpanded;
          store.setCurrentIndustry(data);
        }
      "
      class="card bg px-4 py-2 gradient cursor-pointer text-sm"
      :class="{
        'border-red-800': store.currentIndustry?.industry == data.industry,
      }"
      v-if="!!data"
    >
      <div class="head flex justify-between">
        <div class="flex gap-2 items-start">
          <div
            class="min-w-10 h-10 flex items-center justify-center bg-gray-900"
          >
            <HiBriefcase class="text-lg" />
          </div>
          <p class="text-lg">{{ data.industry }}</p>
        </div>
        <div class="text-right min-w-[140px]">
          <span class="text-[10px] text-gray-400">Стоимость проекта</span>
          <p class="text-xl">
            {{ new Intl.NumberFormat().format(data.cost_project.toFixed(0)) }}
          </p>
        </div>
      </div>
      <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
      <div class="grid grid-cols-[1fr_2fr_2fr] justify-between gap-3">
        <div></div>

        <div class="text-right">
          <span class="text-[10px] text-gray-400 text-nowrap"
            >Среднемесячная ЗП</span
          >
          <p class="text-xl text-gray-300">0</p>
        </div>

        <div class="text-right">
          <span class="text-[10px] text-gray-400">Кол-во рабочих мест</span>
          <p class="text-xl text-gray-300">
            {{ new Intl.NumberFormat().format(data.permanent_jobs) }}
          </p>
        </div>
      </div>
    </div>
    <Collapse :when="isExpanded">
      <template v-for="n in data.projects" :key="n.bin + Math.random()">
        <div
          class="p-4 border-b-2 mb-1 border-red-600 bg cursor-pointer h-max"
          @click="store.setCurrentProject(n)"
        >
          <p>
            {{ n.name_project }}
          </p>
        </div>
      </template>
    </Collapse>
  </div>
</template>

<style scoped lang="scss">
.card {
  border: 2px solid rgba(0, 0, 0, 0);
}
</style>

import { defineStore } from "pinia";

export const useStore = defineStore("store", {
  state: () => {
    return {
      industry: [],
      currentIndustry: null,
      currentProject: null,
      regions: [],
      raions: [],
    };
  },
  actions: {
    setRegion(payload: any) {
      this.regions = payload;
    },
    setRaion(payload: any) {
      this.raions = payload;
    },
    setCurrentProject(payload: any) {
      this.currentProject = payload;
    },
    setCurrentIndustry(payload: any) {
      this.currentProject = null;
      this.currentIndustry = null;
      this.currentIndustry = payload;
    },
    setAll(payload: any) {
      this.industry = payload.reduce((acc: any, cur: any) => {
        if (acc[cur.industry]) {
          acc[cur.industry].cost_project += +cur.cost_project;
          acc[cur.industry].amount_nvestment += +cur.amount_nvestment;
          acc[cur.industry].permanent_jobs += +(
            +cur.permanent_jobs + +cur.temporary_jobs
          );
          acc[cur.industry].projects.push(cur);
        } else {
          acc[cur.industry] = cur;
          acc[cur.industry].amount_nvestment =
            +acc[cur.industry].amount_nvestment;
          acc[cur.industry].cost_project = +acc[cur.industry].cost_project;
          acc[cur.industry].permanent_jobs = +(
            +cur.permanent_jobs + +cur.temporary_jobs
          );
          acc[cur.industry].projects = [];
        }

        return acc;
      }, {});
    },
  },
});

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/style/main.scss";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import Maps from "highcharts/modules/map";
import { createPinia } from "pinia";

Maps(Highcharts);
const pinia = createPinia();

createApp(App).use(pinia).use(router).use(HighchartsVue).mount("#app");

<template>
  <div class="home text-white text-sm">
    <div class="title mb-1 bg px-4 py-2 w-full text-sm">
      <p>
        Проект:
        <b>{{ store?.currentProject ? getDataByKey("initiator_name") : "" }}</b>
      </p>
    </div>
    <div class="grid gap-1 list p-2 grid-cols-4">
      <div class="cursor-pointer active rounded-md p-6 pt-4 pb-2 text-center">
        <p class="text-md mb-4">
          Стоймость проектов <span class="text-[8px] block">(млн тенге)</span>
        </p>
        <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
        <span>{{
          new Intl.NumberFormat().format(getDataByKey("cost_project"))
        }}</span>
      </div>
      <div class="cursor-pointer active rounded-md p-6 pt-4 pb-2 text-center">
        <p class="text-md mb-4">Кол-во рабочих мест</p>
        <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
        <span
          >{{
            new Intl.NumberFormat().format(
              store.currentProject
                ? store.currentProject?.permanent_jobs +
                    store.currentProject?.temporary_jobs
                : store.currentIndustry
                ? store.currentIndustry?.permanent_jobs
                : 0
            )
          }}
        </span>
      </div>
      <div class="cursor-pointer active rounded-md p-6 pt-4 pb-2 text-center">
        <p class="text-md mb-4">Начало реализации / Ввод в эксплуатацию</p>
        <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
        <span>{{
          store.currentProject
            ? `${getDataByKey("regis_start")} - ${getDataByKey(
                "Commissioning"
              )}`
            : "-"
        }}</span>
      </div>
      <div class="cursor-pointer active rounded-md p-6 pt-4 pb-2 text-center">
        <p class="text-md mb-4">
          Сумма инвестиции <span class="text-[8px] block">(млн. тенге)</span>
        </p>
        <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
        <span>{{
          new Intl.NumberFormat().format(getDataByKey("amount_nvestment"))
        }}</span>
      </div>
    </div>
    <div class="grid gap-1 list p-2 grid-cols-[2fr_2fr_1fr]">
      <div class="cursor-pointer rounded-md p-6 pt-4 pb-2">
        <div class="grid grid-cols-[1fr_60px_60px_60px] gap-2">
          <p class="text-md mb-4">Кол-во сотр-ов по ОПВ</p>
          <p class="text-md mb-4 text-center">2022</p>
          <p class="text-md mb-4 text-center">2023</p>
          <p class="text-md mb-4 text-center">2024</p>
        </div>
        <div class="w-full h-[1px] mt-1 mb-3 bg-gray-600"></div>
        <div class="grid grid-cols-[1fr_60px_60px_60px] gap-2">
          <p class="text-md mb-4 text-center"></p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("CNT_22")) }}
          </p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("CNT_23")) }}
          </p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("CNT_24")) }}
          </p>
        </div>
      </div>
      <div class="cursor-pointer rounded-md p-6 pt-4 pb-2">
        <div class="grid grid-cols-[1fr_70px_70px_70px] gap-2">
          <p class="text-md mb-4">Кол-во сотр-ов по ТД</p>
          <p class="text-md mb-4 text-center">2022</p>
          <p class="text-md mb-4 text-center">2023</p>
          <p class="text-md mb-4 text-center">2024</p>
        </div>
        <div class="w-full h-[1px] mt-1 mb-3 bg-gray-600"></div>
        <div class="grid grid-cols-[1fr_70px_70px_70px] gap-2">
          <p class="text-md mb-4 text-center"></p>
          <p class="text-md mb-4 text-center">
            {{
              new Intl.NumberFormat().format(
                verifyCurrentProject("CNT_ESUTD_22")
              )
            }}
          </p>
          <p class="text-md mb-4 text-center">
            {{
              new Intl.NumberFormat().format(
                verifyCurrentProject("CNT_ESUTD_23")
              )
            }}
          </p>
          <p class="text-md mb-4 text-center">
            {{
              new Intl.NumberFormat().format(
                verifyCurrentProject("CNT_ESUTD_24")
              )
            }}
          </p>
        </div>
      </div>
      <div class="cursor-pointer rounded-md p-6 pt-4 pb-2 text-center">
        <p class="text-md mb-4">
          Местные средства <span class="text-[8px] block">(млн тенге)</span>
        </p>
        <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
        <span
          >{{
            new Intl.NumberFormat().format(verifyCurrentProject("local_funds"))
          }}
        </span>
      </div>
    </div>
    <div class="grid gap-1 list p-2 grid-cols-[2fr_2fr_1fr]">
      <div class="cursor-pointer rounded-md p-6 pt-4 pb-2">
        <div class="grid grid-cols-[1fr_100px_100px_100px] gap-2">
          <p class="text-md mb-4">ФОТ</p>
          <p class="text-md mb-4 text-center">2022</p>
          <p class="text-md mb-4 text-center">2023</p>
          <p class="text-md mb-4 text-center">2024</p>
        </div>
        <div class="w-full h-[1px] mt-1 mb-3 bg-gray-600"></div>
        <div class="grid grid-cols-[1fr_100px_100px_100px] gap-2">
          <p class="text-md mb-4 text-center"></p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("FOT_22")) }}
          </p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("FOT_23")) }}
          </p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("FOT_24")) }}
          </p>
        </div>
      </div>
      <div class="cursor-pointer rounded-md p-6 pt-4 pb-2">
        <div class="grid grid-cols-[1fr_70px_70px_70px] gap-2">
          <p class="text-md mb-4">СМЗ</p>
          <p class="text-md mb-4 text-center">2022</p>
          <p class="text-md mb-4 text-center">2023</p>
          <p class="text-md mb-4 text-center">2024</p>
        </div>
        <div class="w-full h-[1px] mt-1 mb-3 bg-gray-600"></div>
        <div class="grid grid-cols-[1fr_70px_70px_70px] gap-2">
          <p class="text-md mb-4 text-center"></p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("SMZ_22")) }}
          </p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("SMZ_23")) }}
          </p>
          <p class="text-md mb-4 text-center">
            {{ new Intl.NumberFormat().format(verifyCurrentProject("SMZ_24")) }}
          </p>
        </div>
      </div>
      <div class="cursor-pointer rounded-md p-6 pt-4 pb-2 text-center">
        <p class="text-md mb-4">
          Иностранные средства
          <span class="text-[8px] block">(млн. тенге)</span>
        </p>
        <div class="w-full h-[1px] mt-1 mb-1 bg-gray-600"></div>
        <span>{{
          new Intl.NumberFormat().format(verifyCurrentProject("foreign_funds"))
        }}</span>
      </div>
    </div>

    <div class="map" v-if="reload">
      <MapRegionChart v-if="store.currentProject" />
      <MapChart v-else />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref } from "vue";
import { useStore } from "@/store";
import data from "@/data/data";
import region from "@/data/obl";
import raion from "@/data/reg";
import { storeToRefs } from "pinia";
import MapRegionChart from "@/components/MapRegionChart.vue";
import MapChart from "@/components/MapChart.vue";

const store = useStore();
const storeRef = storeToRefs(useStore());
store.setAll(data);
store.setRegion(region);
store.setRaion(raion);

const reload = ref(true);

watch(storeRef.currentIndustry, async () => {
  reload.value = false;
  setTimeout(() => {
    reload.value = true;
  }, 10);
});

const getDataByKey = (key: string) => {
  if (store?.currentProject) return store?.currentProject[key] ?? "";

  if (store?.currentIndustry) return store?.currentIndustry[key] ?? "";

  return 0;
};

const verifyCurrentProject = (key: string) => {
  if (store?.currentProject) return store?.currentProject[key] ?? "";

  return 0;
};

watch(storeRef.currentProject, async () => {
  reload.value = false;
  setTimeout(() => {
    reload.value = true;
  }, 10);
});
</script>
<style lang="scss" scoped>
.list {
  > div {
    &.active {
      border-color: #41bfcf;
      background: rgba(23, 130, 145, 0.53);
    }

    border: 3px solid transparent;

    background: rgba(42, 65, 99, 0.58);
  }
}

.map {
  max-height: calc(100vh - 240px);
}

.hc {
  height: calc(100vh - 340px);
}
</style>

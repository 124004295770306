<template>
  <div class="text-white text-sm">
    <div class="title mb-1 bg px-4 py-2 w-full">
      <p>Наименование проектов</p>
    </div>
    <div
      class="all mb-1 bg px-4 py-2 w-full grid grid-cols-[100px_1fr] justify-between items-center"
      v-if="store.industry"
    >
      <p class="text-2xl">Всего</p>
      <div class="flex gap-8">
        <div class="text-right">
          <span class="text-[10px] text-gray-400">Кол-во рабочих мест</span>
          <p class="text-xl">
            {{
              new Intl.NumberFormat().format(
                Object.entries(store.industry)
                  .map((e) => e[1])
                  .reduce((acc: any, e: any) => {
                    acc += +e.permanent_jobs;

                    return acc;
                  }, 0)
                  .toFixed(0)
              )
            }}
          </p>
        </div>
        <div class="text-right">
          <span class="text-[10px] text-gray-400">Стоимость проекта</span>
          <p class="text-xl">
            {{
              new Intl.NumberFormat().format(
                Object.entries(store.industry)
                  .map((e) => e[1])
                  .reduce((acc: any, e: any) => {
                    acc += +e.cost_project;

                    return acc;
                  }, 0)
                  .toFixed(0)
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="sidebar grid gap-2">
      <div>
        <div class="list grid gap-1 overflow-x-scroll">
          <template v-for="d in store.industry" :key="d.bin">
            <def-card :data="d" />
          </template>
        </div>
      </div>
      <!--      <div-->
      <!--        v-if="!!store.currentIndustry?.projects"-->
      <!--        class="list overflow-x-scroll items-start"-->
      <!--      >-->
      <!--        <template-->
      <!--          v-for="n in store.currentIndustry?.projects"-->
      <!--          :key="n.bin + Math.random()"-->
      <!--        >-->
      <!--          <div-->
      <!--            class="p-4 border-b-2 mb-1 border-red-600 bg cursor-pointer h-max"-->
      <!--            @click="store.setCurrentProject(n)"-->
      <!--          >-->
      <!--            <p>-->
      <!--              {{ n.name_project }}-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import DefCard from "@/components/DefCard.vue";
import { useStore } from "@/store";
const store = useStore();
</script>

<style lang="scss" scoped>
.sidebar {
  width: 400px;

  &.active {
    width: 750px;
  }

  .list {
    max-height: calc(100vh - 120px);
  }
}
</style>

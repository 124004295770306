<template>
  <highcharts
    :constructorType="'mapChart'"
    class="hc"
    :options="chartOptions"
    ref="chart"
  />
</template>

<script setup lang="ts">
import worldMap from "@/assets/kz-all.topo";
import { useStore } from "@/store";
import { computed, ref } from "vue";
import { region_code } from "@/enums";

const store = useStore();
const chart = ref();

const data = computed(() => {
  return store.regions.find(
    (e) => +e.id === +store.currentIndustry?.kato_region
  );
});

const dataChart = ref([[region_code[data.value?.name ?? ""], 23]]);

const chartOptions = {
  chart: {
    map: worldMap,
    backgroundColor: "transparent",
  },
  title: {
    text: "",
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      alignTo: "spacingBox",
    },
  },
  colorAxis: {
    min: -30,
    stops: [
      [0, "#a8252b"], // Green
      [0.2, "#f6530f"], // Yellow
      [0.5, "#83966e"], // Yellow
      [0.7, "#1bc7e0"], // Yellow
      [1, "#38ae71"], // Red
    ],
    max: 30,
  },
  series: [
    // {
    //   // Specify points using lat/lon
    //   type: "mappoint",
    //   name: "Cities",
    //   accessibility: {
    //     point: {
    //       valueDescriptionFormat:
    //         "{xDescription}. Lat: " + "{point.lat:.2f}, lon: {point.lon:.2f}.",
    //     },
    //   },
    //   data: [
    //     {
    //       name: "London",
    //       lat: 51.134029,
    //       lon: 71.40904,
    //     },
    //   ],
    // },
    {
      name: "Random data",
      // states: {
      //   hover: {
      //     color: "#BADA55",
      //   },
      // },
      dataLabels: {
        enabled: true,
        format: "{point.name}",
      },
      borderColor: "#84c2d7",
      borderWidth: 0.5,
      allAreas: true,
      data: dataChart.value,
    },
  ],
};
</script>

<style scoped lang="scss"></style>

export const region_code = {
  "Акмолинская область": "kz-am",
  "Актюбинская область": "kz-at",
  "Алматинская область": "kz-aa",
  "Атырауская область": "kz-ar",
  "Западно-Казахстанская область": "kz-wk",
  "Жамбылская область": "kz-zm",
  "Жетысу область": "kz-",
  "Карагандинская область": "kz-qg",
  "Костанайская область": "kz-qs",
  "Кызылординская область": "kz-qo",
  "Мангистауская область": "kz-mg",
  "Павлодарская область": "kz-pa",
  "Северо-Казахстанская область": "kz-nk",
  "Туркестанская область": "kz-sk",
  "Улытау область": "kz-",
  "Восточно-Казахстанская область": "kz-ek",
  "г.Астана": "kz-at",
  "г.Алматы": "kz-aa",
  "г.Шымкент": "kz-sk",
};
